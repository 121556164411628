<template>
  <app-select
    v-if="isSelect"
    :field-key="field"
    :current-value="currentValue"
    :item-key="itemKey"
    :is-enabled="isEnabled"
    :options="calculationDifficulties"
    @updateValue="updateValue"
  />
  <div :class="wrapperClasses" v-else>
    <input type="number" class="input" v-model.number="computedValue" :disabled="!isEnabled" />

    <div class="item-field__units" v-if="isUnitsNeed">
      {{ units }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { isValidNumber } from '@/utils'
import { itemsKeys, calculationDifficulties } from '@/utils/calculationPriceStep'
import AppSelect from '@/components/smart/DeepSettings/CalculationPriceTable/AppSelect'
const selectKeys = [itemsKeys.complexity]
const fieldWithUnits = [itemsKeys.cost, itemsKeys.discount, 'square']

export default {
  name: 'ItemField',
  components: { AppSelect },
  props: {
    currentValue: {
      required: true
    },
    field: {
      type: String,
      default: null
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    itemKey: {
      type: String,
      default: null
    },
    isPercent: Boolean,
    isDebounced: Boolean
  },
  methods: {
    updateValue(val) {
      this.$emit('updateValue', { itemKey: this.itemKey, param: this.field, val })
    }
  },
  created() {
    if (this.isDebounced) {
      this.deboucedUpdateValue = debounce(this.updateValue, 500)
    }
  },
  computed: {
    isSelect() {
      return selectKeys.includes(this.field)
    },
    wrapperClasses() {
      return {
        'item-field': true,
        'item-field--disabled': !this.isEnabled,
        'item-field--percent': this.isPercent
      }
    },
    calculationDifficulties: () => calculationDifficulties,
    computedValue: {
      get() {
        return this.currentValue
      },
      set(val) {
        const [isValidVal] = [
          this.isPercent && isValidNumber(val, true) && val <= 100,
          !this.isPercent && isValidNumber(val) && Number.isInteger(val)
        ].filter(Boolean)
        if (isValidVal) {
          if (this.isDebounced) {
            this.deboucedUpdateValue(val)
          } else {
            this.updateValue(val)
          }
          // this.updateValue(val)
        }
      }
    },
    units() {
      const units = {
        [itemsKeys.discount]: '%',
        [itemsKeys.cost]: '₽',
        square: 'м²'
      }
      return units[this.field]
    },
    isUnitsNeed() {
      return fieldWithUnits.includes(this.field)
    }
  }
}
</script>

<style scoped lang="sass">
.item-field
  width: 100%
  height: rem(40)
  border: rem(1) solid $cool_A
  box-sizing: border-box
  border-radius: rem(4)
  overflow: hidden
  display: flex
  align-items: center
  padding: 0 rem(14)
  font-style: normal
  font-weight: 600
  font-size: rem(14)
  line-height: rem(16)
  color: $black
  &--disabled
    background-color: $default
    border-color: $ec
    color: $black_C
  .input
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 100%
    box-sizing: border-box
    height: 100%
    border: none
    padding: rem(12) rem(14) rem(12) 0
    font-family: $main-font
    font-weight: inherit
    border-radius: inherit
    color: inherit
    outline: none
    background: none
  &--percent
    padding: 0 rem(9)
    .input
      padding: rem(12) 0
      text-align: right
</style>
