<template>
  <ul class="options-list">
    <li
      class="options-list__item"
      v-for="option in availableOptions"
      :key="isSimpleOptions ? option : option.id"
      @click="updateValue(option)"
    >
      {{ isSimpleOptions ? option : option.name }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    currentValue: {
      type: Number,
      required: true
    },
    availableOptions: {
      type: Array,
      required: true
    },
    isSimpleOptions: Boolean
  },
  name: 'AppSelectDropdown',
  methods: {
    updateValue(val) {
      const emittedVal = this.isSimpleOptions ? val : val.id
      this.$emit('updateValue', emittedVal)
    }
  }
}
</script>

<style scoped lang="sass">
.options-list
  display: grid
  padding: 0
  margin: 0
  list-style: none
  &__item
    width: 100%
    box-sizing: border-box
    padding: rem(8) rem(10)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(16)
    color: $light-black
    cursor: pointer
    border-radius: rem(2)
    transition: .3s
    @media (any-hover: hover)
      &:hover
        background: $ec
        color: $black
</style>
