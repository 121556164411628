<template>
  <div class="renamer">
    <button class="renamer__btn" @click="handle">
      <img src="@/assets/img/icons/deep-settings/edit.svg" alt="" />
    </button>
    <input
      type="text"
      v-model="computedValue"
      class="renamer__input"
      ref="input"
      :disabled="isInputDisabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  name: 'ModalSectionItemRename',
  data: () => ({
    isInputDisabled: true
  }),
  methods: {
    handle() {
      this.isInputDisabled = false
      this.$nextTick().then(() => this.$refs.input.focus())
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('updateSectorName', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.renamer
  height: 100%
  position: relative
  display: grid
  grid-template-columns: rem(40) 1fr
  &__input, &__btn
    height: 100%
    box-sizing: border-box
  &__btn
    @extend .clear-btn
    display: flex
    align-items: center
    justify-content: center
    img
      transition: .3s
      filter: invert(97%) sepia(7%) saturate(6%) hue-rotate(266deg) brightness(105%) contrast(68%)
    @media (any-hover: hover)
      &:hover
        img
          filter: invert(23%) sepia(67%) saturate(7322%) hue-rotate(350deg) brightness(90%) contrast(118%)
  &__input
    border: none
    text-overflow: ellipsis
    outline: none
    font-family: $main-font
    @extend %14R114
    font-weight: 600
    color: $black
    background: none
    &:disabled
      background: none
</style>
