<template>
  <div class="calculation-price-step">
    <calculation-price-table />
    <steps-nav :current-step="currentStep" @prev="prev">
      <slot v-if="$slots.default" />
    </steps-nav>
  </div>
</template>

<script>
import CalculationPriceTable from '@/components/smart/DeepSettings/CalculationPriceTable'
import StepsNav from '@/components/smart/DeepSettings/StepsNav'
export default {
  name: 'CalculationPriceStep',
  props: {
    currentStep: {
      type: String,
      required: true
    }
  },
  components: { StepsNav, CalculationPriceTable },
  methods: {
    prev() {
      this.$emit('prev')
    }
  }
}
</script>

<style scoped></style>
