<template>
  <div class="calculation-price-table">
    <div class="table-head">
      <div class="table-head__title" v-for="[key, val] in tableCells" :key="key">
        {{ val }}
      </div>
    </div>
    <div class="table-body">
      <body-item
        v-for="[key, val] in bodyItemsToDisplay"
        :key="key"
        :item="val"
        :item-key="key"
        :is-loading="isLoading"
        @updateValue="requestNewCost"
      >
        <template>{{ getParameterName(key) }}</template>
        <template #item--thermalCalculationSP23="{ item }">
          <app-button
            color="grey"
            transparent
            is-reverse
            :is-disabled="!item.isEnabled"
            @click="openModal(key)"
          >
            Настроить
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/deep-settings.svg')" />
            </template>
          </app-button>
        </template>
        <template #item--thermalCalculationSP50="{ item }">
          <app-button
            color="grey"
            transparent
            is-reverse
            :is-disabled="!item.isEnabled"
            @click="openModal(key)"
          >
            Настроить
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/deep-settings.svg')" />
            </template>
          </app-button>
        </template>
        <template #item--windZones="{ item }">
          <app-button
            color="grey"
            transparent
            is-reverse
            :is-disabled="!item.isEnabled"
            @click="openModal(key)"
          >
            Настроить
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/deep-settings.svg')" />
            </template>
          </app-button>
        </template>
        <template #item--plateLayout="{ item }">
          <app-button
            color="grey"
            transparent
            is-reverse
            :is-disabled="!item.isEnabled"
            @click="openModal(key)"
          >
            Настроить
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/deep-settings.svg')" />
            </template>
          </app-button>
        </template>
      </body-item>
    </div>
    <button
      @click="isShowAdditionalOptions = !isShowAdditionalOptions"
      class="showMore"
      :class="{ 'showMore--active': isShowAdditionalOptions }"
    >
      <span class="showMore__icon">
        <img src="@/assets/img/icons/deep-settings/show-more.svg" alt="↓" />
      </span>
      {{ showMoreBtnText }}
    </button>
    <modal :is-modal-open="isModalOpen" @close="close" :is-show-close="false">
      <template #body>
        <modal-content-sectors v-if="isWithSectors" :parameter="currentModalKey" @close="close">
          <template #title> {{ getParameterName(currentModalKey) }} </template>
        </modal-content-sectors>
        <modal-content-sbe v-else :parameter="currentModalKey" @close="close">
          <template #title> {{ getParameterName(currentModalKey) }} </template>
        </modal-content-sbe>
      </template>
    </modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { tableCells, parametersNames, itemsKeys, parameters } from '@/utils/calculationPriceStep'
import { getCostByParams, updateCalculation } from '@/api/moderator'
import BodyItem from '@/components/smart/DeepSettings/CalculationPriceTable/BodyItem'
import AppButton from '@/components/elements/AppButton'
import Modal from '@/components/elements/Modals/Modal'
import ModalContentSectors from '@/components/smart/DeepSettings/CalculationPriceTable/ModalContentSectors'
import ModalContentSbe from '@/components/smart/DeepSettings/CalculationPriceTable/ModalContentSbe'
import { cloneDeep } from 'lodash'
import { handleError } from '@/utils/deepSettings'

const specialParameters = [parameters.windZones, parameters.plateLayout]

export default {
  name: 'CalculationPriceTable',
  components: { ModalContentSectors, Modal, AppButton, BodyItem, InlineSvg, ModalContentSbe },
  data: () => ({
    isShowAdditionalOptions: false,
    isModalOpen: false,
    currentModalKey: null,
    isLoading: false,
    isWithSectors: false
  }),
  methods: {
    ...mapMutations('moderator', {
      SET_COST_FOR_ALL_PRICE_STEP_ITEMS: 'SET_COST_FOR_ALL_PRICE_STEP_ITEMS'
    }),
    ...mapActions('moderator', {
      setInitialSectorsToPriceStep: 'setInitialSectorsToPriceStep',
      updateSpecialCalculationParam: 'updateSpecialCalculationParam',
      restoreCalculationPriceParamToDefault: 'restoreCalculationPriceParamToDefault',
      commonUpdateSpecialCalculationParam: 'commonUpdateSpecialCalculationParam'
    }),
    getParameterName(key) {
      return parametersNames[key]
    },
    requestNewCost(obj) {
      const { itemKey: key, param, val } = obj
      const currentParams = { ...this.calculationPrice[key] }
      currentParams[param] = val
      this.getCurrentMutation(param, key, val)({ currentParams, key, param, val })
    },
    updateValueWithoutRequestNewCost({ currentParams, key, param, val }) {
      this.updateValue({ key, param, val, cost: currentParams.cost })
    },
    restoreToDefault({ key }) {
      this.restoreCalculationPriceParamToDefault(key)
    },
    updateValueForSpecialParameters({ currentParams, key }) {
      const { $i18n, $route, getAllDeepSettings } = this
      const { locale: lang } = $i18n
      const { hash } = $route.params
      this.isLoading = true
      const payload = [
        {
          ...currentParams,
          name: key
        }
      ]
      getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
        .then(response => {
          const [val] = payload
          const normalizedData = response.data[key]
          val.dist = { ...response.data[key].dist }
          val.basePrice = { ...response.data[key].basePrice }
          val.sectors = val.sectors.map(sector => {
            return {
              ...sector,
              cost: normalizedData.price[sector.uid]
            }
          })
          this.updateSpecialCalculationParam({ key, val })
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          handleError(this.$notify, e)
        })
    },
    updateValueWithRequestNewCost({ currentParams, key, param, val }) {
      const { $i18n, $route, getAllDeepSettings } = this
      const { locale: lang } = $i18n
      const { hash } = $route.params
      this.isLoading = true
      const payload = [
        {
          ...currentParams,
          name: key
        }
      ]
      getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
        .then(response => {
          const cost = response.data[key]

          this.updateValue({ key, param, val, cost })
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          handleError(this.$notify, e)
        })
    },
    getCurrentMutation(param, key, val) {
      const [currentMutation] = [
        param === itemsKeys.discount && this.updateValueWithoutRequestNewCost,
        param === itemsKeys.isEnabled && !val && this.restoreToDefault,
        specialParameters.includes(key) && this.updateValueForSpecialParameters,
        this.updateValueWithRequestNewCost
      ].filter(Boolean)
      return currentMutation
    },
    updateValue({ key, param, val, cost }) {
      this.commonUpdateSpecialCalculationParam({
        key,
        param,
        val,
        cost,
        isNeedUpdateCipher: param === itemsKeys.isEnabled
      })
    },
    openModal(key) {
      this.currentModalKey = key
      if (key === 'windZones' || key === 'plateLayout') {
        this.isWithSectors = true
      }
      this.isModalOpen = !this.isModalOpen
    },
    close() {
      this.currentModalKey = null
      this.isModalOpen = false
      this.isWithSectors = false
    }
  },
  computed: {
    ...mapState('moderator', {
      calculationPrice: state => state.calculationPrice,
      userId: state => state.userId
    }),
    ...mapState({
      sectors: state => state.sectors,
      result: state => state.result
    }),
    ...mapGetters('moderator', {
      getAllDeepSettings: 'getAllDeepSettings'
    }),
    bodyItems() {
      return Object.entries(this.calculationPrice)
    },
    bodyItemsToDisplay() {
      return this.isShowAdditionalOptions
        ? this.bodyItems
        : this.bodyItems.filter(([_, val]) => !val.isAdditional)
    },
    tableCells: () => Object.entries(tableCells),
    showMoreBtnText() {
      const showStr = 'Показать еще'
      const hideStr = 'Скрыть дополнительные'
      const dynamicStr = this.isShowAdditionalOptions ? hideStr : showStr
      return `${dynamicStr} виды работ`
    }
  },
  async mounted() {
    if (
      !(
        this.bodyItems.filter(([_, val]) => val.isAdditional).filter(([_, val]) => val.isEnabled)
          .length === 0
      )
    ) {
      this.isShowAdditionalOptions = true
    }
    this.isLoading = true
    const { $i18n, userId, sectors, result, getAllDeepSettings, $route, calculationPrice } = this
    const { locale: lang } = $i18n
    const { hash } = $route.params
    await updateCalculation({
      lang,
      userId,
      sectors,
      result,
      deepSettings: { ...getAllDeepSettings },
      action: 'update',
      hash
    })
    this.setInitialSectorsToPriceStep(sectors)
    const clonedObj = cloneDeep(calculationPrice)
    const payload = Object.entries(clonedObj)
      .map(([key, val]) => {
        return {
          name: key,
          ...val
        }
      })
      .filter(item => item.isEnabled)
    getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
      .then(response => {
        this.SET_COST_FOR_ALL_PRICE_STEP_ITEMS(response.data)
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        handleError(this.$notify, e)
      })
  }
}
</script>

<style scoped lang="sass">
.calculation-price-table
  // overflow-x: auto
  margin-bottom: rem(48)
  .table-head, .table-body
    // width: rem(1300)
  .table-head
    display: grid
    grid-template-columns: 1fr repeat(3, rem(150)) rem(70)
    gap: rem(15)
    margin-bottom: rem(24)
    box-shadow: inset 0 rem(-1) 0 $cool_A
    color: $black_C
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(16)
    height: rem(32)
  .table-body
    display: grid
    row-gap: rem(8)
  .showMore
    @extend .clear-btn
    @extend %14R114
    color: $red
    text-decoration: underline
    padding: rem(12) rem(15) rem(12) rem(7)
    display: flex
    align-items: center
    margin-top: rem(20)
    &__icon
      width: rem(16)
      display: flex
      align-items: center
      justify-content: center
      margin-right: rem(23)
      transition: .3s
    &--active
      .showMore
        &__icon
          transform: scaleY(-1)
</style>
