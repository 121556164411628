<template>
  <div class="item-toggle">
    <input
      type="checkbox"
      class="item-toggle__input"
      v-model="computedIsEnabled"
      :id="itemKey"
      hidden
    />
    <label :for="itemKey" class="item-toggle__label"></label>
  </div>
</template>

<script>
export default {
  name: 'ItemToggle',
  props: {
    isEnabled: {
      type: Boolean,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  },
  computed: {
    computedIsEnabled: {
      get() {
        return this.isEnabled
      },
      set(val) {
        this.$emit('updateStatus', { itemKey: this.itemKey, param: this.field, val })
      }
    }
  }
}
</script>

<style scoped lang="sass">
.item-toggle
  &__label
    width: rem(30)
    height: rem(20)
    border: rem(1) solid $ec
    border-radius: rem(12)
    box-sizing: border-box
    background: $ec
    display: flex
    position: relative
    cursor: pointer
    &:before
      position: absolute
      content: ""
      top: 50%
      transform: translate(rem(3), -50%)
      height: rem(14)
      width: rem(14)
      border-radius: 50%
      background-color: $cool_A
      transition: all .3s, background-image 0s
      background-repeat: no-repeat
      background-position: center center
    @media(any-hover: hover)
      &:hover
        box-shadow:  0 0 rem(5) rgba(51, 51, 51, 0.2), 0 rem(5) rem(15) rgba(51, 51, 51, 0.1)
        border-color: $white
        background-color: $white
        &:before
          background-color: #EA454E
  &__input
    &:checked
      + label
          border-color: $cool_A
          background: $white
          &:before
            background-color: $green
            transform: translate(calc(100% - 3px), -50%)
            background-image: url("~@/assets/img/icons/deep-settings/toggle-check.svg")
          @media (any-hover: hover)
            &:hover
              &:before
                background-color: #94E3B5
                background-image: url("~@/assets/img/icons/deep-settings/toggle-chevron.svg")
</style>
