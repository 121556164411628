<template>
  <div class="select-wrapper">
    <div
      class="app-select"
      ref="select"
      :class="{ 'app-select--disabled': !isEnabled }"
      :name="`select--${fieldKey}--${itemKey}`"
    >
      <div class="field">
        <div class="field__text">{{ currentValueName }}</div>
        <div class="field__icon">
          <img src="@/assets/img/icons/filter--select.svg" alt="↓" />
        </div>
      </div>
      <tippy
        v-if="isEnabled"
        appendTo="parent"
        trigger="click"
        :to="`select--${fieldKey}--${itemKey}`"
        theme="light"
        distance="0"
        placement="bottom"
        interactive
      >
        <app-select-dropdown
          :available-options="availableOptions"
          :current-value="currentValue"
          :is-simple-options="!isArrayOfObjects"
          @updateValue="updateValue"
        />
      </tippy>
    </div>
  </div>
</template>

<script>
import AppSelectDropdown from '@/components/smart/DeepSettings/CalculationPriceTable/AppSelectDropdown'
export default {
  name: 'AppSelect',
  props: {
    fieldKey: {
      type: String,
      required: true
    },
    currentValue: {
      type: Number,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateValue(val) {
      this.$refs.select._tippy.hide()
      this.$emit('updateValue', val)
    }
  },
  components: { AppSelectDropdown },
  computed: {
    availableOptions() {
      return this.isArrayOfObjects
        ? this.options.filter(option => option.id !== this.currentValue)
        : this.options.filter(option => option !== this.currentValue)
    },
    isArrayOfObjects() {
      return this.options.some(option => typeof option === 'object')
    },
    currentValueName() {
      return this.isArrayOfObjects
        ? this.options.find(option => option.id === this.currentValue).name
        : this.currentValue
    }
  }
}
</script>

<style scoped lang="sass">
.select-wrapper
  position: relative
  width: 100%
  &::v-deep
    .tippy-popper
      width: 100%
      max-width: 100%
      left: 0
      .tippy-tooltip
        background: $white
        box-sizing: border-box
        text-align: left
        padding: rem(5)
.app-select
  .field
    display: flex
    align-items: center
    justify-content: space-between
    height: rem(40)
    max-width: 100%
    border-radius: rem(4)
    border: rem(1) solid $cool_A
    padding: rem(12) rem(14)
    box-sizing: border-box
    cursor: pointer
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(16)
    color: $black
  &--disabled
    .field
      background-color: $default
      border-color: $ec
      color: $black_C
      cursor: default
      &__icon
        filter: grayscale(1)
        opacity: .3
</style>
