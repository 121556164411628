<template>
  <div class="modal-sector-item">
    <div class="name">
      <modal-section-item-rename
        :value="item.name"
        @updateSectorName="updateSectorParam($event, 'name')"
      />
    </div>
    <div class="square">
      <item-field
        item-key="square"
        :current-value="item.square"
        is-enabled
        field="square"
        is-debounced
        @updateValue="updateSectorSquare"
      />
    </div>

    <div class="type">
      <app-select
        field-key="insulationType"
        :current-value="item.insulationType"
        :item-key="`insulationType--${item.uid}`"
        :options="insulationTypes"
        @updateValue="updateSectorParam($event, 'insulationType')"
        is-enabled
      />
    </div>
    <div class="complexity">
      <app-select
        field-key="complexity"
        :current-value="item.complexity"
        :item-key="`complexity--${item.uid}`"
        :options="calculationDifficulties"
        @updateValue="updateSectorParam($event, 'complexity')"
        is-enabled
      />
    </div>
    <div class="cost">
      <item-field :current-value="item.cost" field="cost" :is-enabled="false" />
    </div>
    <div class="button">
      <app-button size="xs" @click="deleteSector">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/deep-settings/delete.svg')" />
        </template>
      </app-button>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { calculationDifficulties } from '@/utils/calculationPriceStep'
import { mapState } from 'vuex'
import ItemField from '@/components/smart/DeepSettings/CalculationPriceTable/ItemField'
import ModalSectionItemRename from '@/components/smart/DeepSettings/CalculationPriceTable/ModalSectionItemRename'
import AppSelect from '@/components/smart/DeepSettings/CalculationPriceTable/AppSelect'
import AppButton from '@/components/elements/AppButton'
export default {
  components: { AppButton, AppSelect, ModalSectionItemRename, ItemField, InlineSvg },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: 'ModalSectorItem',
  methods: {
    deleteSector() {
      this.$emit('deleteSector', this.item.uid)
    },
    updateSectorParam($event, param) {
      const { uid } = this.item
      this.$emit('updateSectorParam', {
        uid,
        param,
        val: $event
      })
    },
    updateSectorSquare({ param, val }) {
      this.updateSectorParam(val, param)
    }
  },
  computed: {
    ...mapState('moderator', {
      insulationTypes: state => state.insulationTypes
    }),
    calculationDifficulties: () => calculationDifficulties
  }
}
</script>

<style scoped lang="sass">
.modal-sector-item
  display: grid
  grid-template-columns: 1fr repeat(3, rem(120)) rem(150) rem(30)
  gap: rem(15)
  .button
    display: flex
    align-items: center
    justify-content: center
</style>
