<script>
export default {
  name: 'FieldPlug',
  render: function (createElement) {
    return createElement('div', { class: 'field-plug' })
  }
}
</script>

<style scoped lang="sass">
.field-plug
  height: 100%
  position: relative
  display: flex
  justify-content: center
  align-items: center
  margin: auto
  &:after
    content: ""
    position: relative
    display: block
    height: rem(1)
    width: rem(16)
    background: $cool_B
</style>
