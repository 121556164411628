<template>
  <div class="modal-content">
    <div class="modal-content__head">
      <div class="title">
        <slot name="title" />
      </div>
      <button class="close" @click="close">
        <img src="@/assets/img/icons/deep-settings/close.svg" alt="❌" />
      </button>
    </div>
    <div v-if="currentPriceParameter.count" class="sbe-item">
      <div class="sbe-row">
        <div class="sbe-text">К-во расчетов</div>
        <div class="sbe-control">
          <item-field
            :current-value="currentPriceParameter.count"
            :is-enabled="currentPriceParameter.isEnabled"
            field="count"
            item-key="thermalCalculationSP23"
            @updateValue="updateValue"
          />
        </div>
      </div>
      <div class="sbe-row" v-for="sbe in currentPriceParameter.sbeList" :key="sbe.name">
        <div class="sbe-text">
          {{ sbe.name }}
        </div>
        <div class="sbe-control">
          <item-toggle-sbe
            :is-enabled="sbe.isEnabled"
            :sbe-key="sbe.key"
            sbeName="thermalCalculationSP23"
            @updateValueSbe="updateValueSbe"
          />
        </div>
      </div>
    </div>
    <div v-if="currentPriceParameter.complexity" class="sbe-item">
      <div class="sbe-row">
        <div class="sbe-text">Сложность</div>
        <div class="sbe-control">
          <item-field
            :current-value="currentPriceParameter.complexity"
            :is-enabled="currentPriceParameter.isEnabled"
            field="complexity"
            item-key="thermalCalculationSP50"
            @updateValue="updateValue"
          />
        </div>
      </div>
      <div class="sbe-row" v-for="sbe in currentPriceParameter.sbeList" :key="sbe.name">
        <div class="sbe-text">
          {{ sbe.name }}
        </div>
        <div class="sbe-control">
          <item-toggle-sbe
            :is-enabled="sbe.isEnabled"
            :sbe-key="sbe.key"
            sbeName="thermalCalculationSP50"
            @updateValueSbe="updateValueSbe"
          />
        </div>
      </div>
    </div>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="false"
    ></loading>
  </div>
</template>

<script>
// import { getCostByParams } from '@/api/moderator'
import ItemField from '@/components/smart/DeepSettings/CalculationPriceTable/ItemField'
import itemToggleSbe from '@/components/smart/DeepSettings/CalculationPriceTable/itemToggleSbe'

import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { getCostByParams } from '@/api/moderator'
import { handleError } from '@/utils/deepSettings'
export default {
  props: {
    parameter: {
      type: String,
      required: true
    },
    sbes: {}
  },
  data: () => ({
    isLoading: false
  }),
  components: { ItemField, itemToggleSbe },
  // components: { ItemField },
  name: 'ModalContentSbe',
  methods: {
    close() {
      this.$emit('close')
    },
    updateValueWithRequestNewCost({ itemKey, param, val }) {
      const { $i18n, $route, getAllDeepSettings } = this
      const { locale: lang } = $i18n
      const { hash } = $route.params
      const currentParams = { ...this.calculationPrice[itemKey] }
      this.isLoading = true
      currentParams[param] = val
      const payload = [
        {
          ...currentParams,
          name: itemKey
        }
      ]
      getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
        .then(response => {
          const cost = response.data[itemKey]
          this.updateSbeParamInPriceByKey({ itemKey, param, val, cost })
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          handleError(this.$notify, e)
        })
    },
    updateValueSbe(obj) {
      this.UPDATE_SBE(obj)
      const { sbeName } = obj
      const { $i18n, $route, getAllDeepSettings } = this
      const { locale: lang } = $i18n
      const { hash } = $route.params
      const currentParams = { ...this.calculationPrice[sbeName] }
      this.isLoading = true
      const payload = [
        {
          ...currentParams,
          name: sbeName
        }
      ]
      getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
        .then(response => {
          const cost = response.data[sbeName]
          this.UPDATE_SBE_DIST({ sbeName, cost })
          this.isLoading = false
        })
        .catch(e => {
          this.isLoading = false
          handleError(this.$notify, e)
        })
    },
    updateValue(obj) {
      this.updateValueWithRequestNewCost(obj)
    },
    ...mapActions('moderator', { updateSbeParamInPriceByKey: 'updateSbeParamInPriceByKey' }),
    ...mapMutations('moderator', { UPDATE_SBE: 'UPDATE_SBE', UPDATE_SBE_DIST: 'UPDATE_SBE_DIST' })
  },
  computed: {
    ...mapGetters('moderator', ['getPriceItemByParameter']),
    ...mapGetters('moderator', {
      getAllDeepSettings: 'getAllDeepSettings'
    }),
    ...mapState('moderator', {
      calculationPrice: state => state.calculationPrice
    }),
    hash() {
      return this.$route.params.hash
    },

    currentPriceParameter() {
      return this.getPriceItemByParameter(this.parameter)
    }
  },
  mounted() {}
}
</script>

<style scoped lang="sass">
.sbe
  &-text
    color: $black_C
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(16)
    width: 40%
  &-row
    margin-bottom: rem(32)
    display: flex
    align-items: center
  &-control
    width: 60%
.modal-content
  width: rem(500)
  padding: rem(30)
  box-sizing: border-box
  &__head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: rem(48)
    .title
      @extend %H324B
      color: $black
    .close
      @extend %calculation-price-close
  &__sectors
    max-height: rem(343)
    overflow-y: auto
    @extend %styled-native-scrollbar
    margin: 0 rem(-30) rem(16)
    padding: 0 rem(30)
    .titles
      background: $white
      z-index: 1
      display: grid
      grid-template-columns: 1fr repeat(3, rem(120)) rem(150) rem(30)
      gap: rem(15)
      padding-bottom: rem(16)
      box-shadow: inset 0 rem(-1) 0 $cool_A
      position: sticky
      top: 0
      &__item
        @extend %14R114
        color: $black_C
        font-weight: 600
    .items
      margin: rem(16) 0 0
      display: grid
      gap: rem(8)
      // max-height: rem(280)
      // overflow-y: auto
      // @extend %styled-native-scrollbar
</style>
