var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calculation-price-table"},[_c('div',{staticClass:"table-head"},_vm._l((_vm.tableCells),function(ref){
var key = ref[0];
var val = ref[1];
return _c('div',{key:key,staticClass:"table-head__title"},[_vm._v(" "+_vm._s(val)+" ")])}),0),_c('div',{staticClass:"table-body"},_vm._l((_vm.bodyItemsToDisplay),function(ref){
var key = ref[0];
var val = ref[1];
return _c('body-item',{key:key,attrs:{"item":val,"item-key":key,"is-loading":_vm.isLoading},on:{"updateValue":_vm.requestNewCost},scopedSlots:_vm._u([{key:"item--thermalCalculationSP23",fn:function(ref){
var item = ref.item;
return [_c('app-button',{attrs:{"color":"grey","transparent":"","is-reverse":"","is-disabled":!item.isEnabled},on:{"click":function($event){return _vm.openModal(key)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('inline-svg',{attrs:{"src":require('@/assets/img/icons/dashboard/deep-settings.svg')}})]},proxy:true}],null,true)},[_vm._v(" Настроить ")])]}},{key:"item--thermalCalculationSP50",fn:function(ref){
var item = ref.item;
return [_c('app-button',{attrs:{"color":"grey","transparent":"","is-reverse":"","is-disabled":!item.isEnabled},on:{"click":function($event){return _vm.openModal(key)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('inline-svg',{attrs:{"src":require('@/assets/img/icons/dashboard/deep-settings.svg')}})]},proxy:true}],null,true)},[_vm._v(" Настроить ")])]}},{key:"item--windZones",fn:function(ref){
var item = ref.item;
return [_c('app-button',{attrs:{"color":"grey","transparent":"","is-reverse":"","is-disabled":!item.isEnabled},on:{"click":function($event){return _vm.openModal(key)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('inline-svg',{attrs:{"src":require('@/assets/img/icons/dashboard/deep-settings.svg')}})]},proxy:true}],null,true)},[_vm._v(" Настроить ")])]}},{key:"item--plateLayout",fn:function(ref){
var item = ref.item;
return [_c('app-button',{attrs:{"color":"grey","transparent":"","is-reverse":"","is-disabled":!item.isEnabled},on:{"click":function($event){return _vm.openModal(key)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('inline-svg',{attrs:{"src":require('@/assets/img/icons/dashboard/deep-settings.svg')}})]},proxy:true}],null,true)},[_vm._v(" Настроить ")])]}}],null,true)},[[_vm._v(_vm._s(_vm.getParameterName(key)))]],2)}),1),_c('button',{staticClass:"showMore",class:{ 'showMore--active': _vm.isShowAdditionalOptions },on:{"click":function($event){_vm.isShowAdditionalOptions = !_vm.isShowAdditionalOptions}}},[_vm._m(0),_vm._v(" "+_vm._s(_vm.showMoreBtnText)+" ")]),_c('modal',{attrs:{"is-modal-open":_vm.isModalOpen,"is-show-close":false},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.isWithSectors)?_c('modal-content-sectors',{attrs:{"parameter":_vm.currentModalKey},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.getParameterName(_vm.currentModalKey))+" ")]},proxy:true}],null,false,449398012)}):_c('modal-content-sbe',{attrs:{"parameter":_vm.currentModalKey},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.getParameterName(_vm.currentModalKey))+" ")]},proxy:true}])})]},proxy:true}])}),_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"color":"#E30713","height":145,"width":145,"opacity":0.7,"backgroundColor":"#eeeeee","z-index":3,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"showMore__icon"},[_c('img',{attrs:{"src":require("@/assets/img/icons/deep-settings/show-more.svg"),"alt":"↓"}})])}]

export { render, staticRenderFns }