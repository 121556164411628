<template>
  <div class="modal-content">
    <div class="modal-content__head">
      <div class="title">
        <slot name="title" />
      </div>
      <button class="close" @click="close">
        <img src="@/assets/img/icons/deep-settings/close.svg" alt="❌" />
      </button>
    </div>
    <div class="modal-content__sectors" ref="sectors" v-if="currentSectors.length">
      <div class="titles">
        <div class="titles__item" v-for="(title, i) in titles" :key="i">
          {{ title }}
        </div>
      </div>
      <div class="items">
        <modal-sector-item
          v-for="sector in currentSectors"
          :key="sector.uid"
          :item="sector"
          @deleteSector="deleteSector"
          @updateSectorParam="updateSectorParam($event, sector)"
        />
      </div>
    </div>
    <div class="modal-content__footer">
      <app-button @click="addSector" is-add-button>
        <template #icon>
          <img src="@/assets/img/icons/deep-settings/add.svg" alt="+" />
        </template>
        Добавить участок
      </app-button>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="false"
    ></loading>
  </div>
</template>

<script>
import { getCostByParams } from '@/api/moderator'
import { mapGetters, mapActions, mapState } from 'vuex'
import ModalSectorItem from '@/components/smart/DeepSettings/CalculationPriceTable/ModalSectorItem'
import AppButton from '@/components/elements/AppButton'
import { cloneDeep } from 'lodash'
import { getRandomId } from '@/utils'
import { handleError } from '@/utils/deepSettings'
const titles = ['Участок', 'Площадь, м²', 'Тип ГИ', 'Сложность', 'Стоимость']
const nameParam = 'name'
export default {
  props: {
    parameter: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: false
  }),
  name: 'ModalContentSectors',
  components: { AppButton, ModalSectorItem },
  methods: {
    ...mapActions('moderator', {
      updateSectorParamInPriceByKey: 'updateSectorParamInPriceByKey',
      deleteSectorFromPrice: 'deleteSectorFromPrice',
      addSectorToPrice: 'addSectorToPrice'
    }),
    close() {
      this.$emit('close')
    },
    deleteSector(uid) {
      const { parameter } = this
      this.deleteSectorFromPrice({
        parameter,
        uid
      })
    },
    addSector() {
      const {
        parameter,
        defaultSector,
        clonedCurrentPriceParameter,
        $i18n,
        $route,
        getAllDeepSettings
      } = this
      const { locale: lang } = $i18n
      const { hash } = $route.params
      this.isLoading = true
      const uid = getRandomId()
      const payload = [
        {
          ...clonedCurrentPriceParameter,
          name: parameter,
          sectors: [
            {
              ...defaultSector,
              uid
            }
          ]
        }
      ]
      getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
        .then(response => {
          const responseBasePrice = response.data[parameter].basePrice
          const responseDist = response.data[parameter].dist
          const responseCost = response.data[parameter].price
          this.addSectorToPrice({
            parameter,
            cost: responseCost[uid],
            uid,
            basePrice: responseBasePrice[uid],
            dist: responseDist[uid]
          })
          this.isLoading = false
          const { sectors } = this.$refs
          if (sectors) {
            this.$nextTick().then(() => {
              sectors.scrollTo(0, sectors.scrollHeight)
            })
          }
        })
        .catch(e => {
          this.isLoading = false
          handleError(this.$notify, e)
        })
    },
    updateSectorParam({ uid, param, val }, sector) {
      if (!this.isLoading) {
        const { parameter, $i18n, $route, getAllDeepSettings } = this
        const { locale: lang } = $i18n
        const { hash } = $route.params
        if (param === nameParam) {
          this.setNewValueToParam(false, parameter, param, uid, val)
        } else {
          this.isLoading = true
          const { clonedCurrentPriceParameter } = this
          const payload = [
            {
              ...clonedCurrentPriceParameter,
              sectors: [{ ...sector, [param]: val }],
              name: parameter
            }
          ]
          getCostByParams({ lang, payload, hash, deepSettings: { ...getAllDeepSettings } })
            .then(response => {
              const responseCost = response.data[parameter]
              this.setNewValueToParam(true, parameter, param, uid, val, responseCost)
              this.isLoading = false
            })
            .catch(e => {
              this.isLoading = false
              handleError(this.$notify, e)
            })
        }
      }
    },
    setNewValueToParam(isNeedUpdateCost, parameter, param, uid, val, cost) {
      this.updateSectorParamInPriceByKey({
        isNeedUpdateCost,
        key: parameter,
        param,
        uid,
        val,
        cost
      })
    }
  },
  computed: {
    ...mapState('moderator', {
      defaultSector: state => state.defaultSector
    }),
    ...mapGetters('moderator', ['getPriceItemByParameter']),
    ...mapGetters('moderator', {
      getAllDeepSettings: 'getAllDeepSettings'
    }),
    currentPriceParameter() {
      return this.getPriceItemByParameter(this.parameter)
    },
    hash() {
      return this.$route.params.hash
    },
    currentSectors() {
      return this.currentPriceParameter.sectors
    },
    clonedCurrentPriceParameter() {
      return cloneDeep(this.currentPriceParameter)
    },
    titles: () => titles
  }
}
</script>

<style scoped lang="sass">
.modal-content
  width: rem(960)
  padding: rem(30)
  box-sizing: border-box
  &__head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: rem(48)
    .title
      @extend %H324B
      color: $black
    .close
      @extend %calculation-price-close
  &__sectors
    max-height: rem(343)
    overflow-y: auto
    @extend %styled-native-scrollbar
    margin: 0 rem(-30) rem(16)
    padding: 0 rem(30)
    .titles
      background: $white
      z-index: 1
      display: grid
      grid-template-columns: 1fr repeat(3, rem(120)) rem(150) rem(30)
      gap: rem(15)
      padding-bottom: rem(16)
      box-shadow: inset 0 rem(-1) 0 $cool_A
      position: sticky
      top: 0
      &__item
        @extend %14R114
        color: $black_C
        font-weight: 600
    .items
      margin: rem(16) 0 0
      display: grid
      gap: rem(8)
      // max-height: rem(280)
      // overflow-y: auto
      // @extend %styled-native-scrollbar
</style>
