<template>
  <div class="table-row" :class="{ 'table-row--disabled': !item.isEnabled }">
    <div class="table-row__cell">
      <item-toggle
        :is-enabled="item.isEnabled"
        :field="fields.isEnabled"
        :item-key="itemKey"
        @updateStatus="updateValue"
      />
      <div class="title">
        <slot />
      </div>
    </div>
    <div class="table-row__cell table-row__cell--double" v-if="$scopedSlots[`item--${itemKey}`]">
      <slot :name="`item--${itemKey}`" :item="item" />
    </div>
    <template v-else>
      <div class="table-row__cell">
        <item-field
          :current-value="item.count"
          v-if="item.count"
          :is-enabled="item.isEnabled"
          :field="fields.count"
          is-debounced
          @updateValue="updateValue"
          :item-key="itemKey"
        />
        <field-plug v-else />
      </div>
      <div class="table-row__cell">
        <item-field
          :current-value="item.complexity"
          v-if="item.complexity"
          :is-enabled="item.isEnabled"
          :field="fields.complexity"
          :item-key="itemKey"
          @updateValue="updateValue"
        />
        <field-plug v-else />
      </div>
    </template>
    <div class="table-row__cell">
      <item-field :current-value="item.cost" :field="fields.cost" :is-enabled="false" />
    </div>
    <div class="table-row__cell">
      <item-field
        is-percent
        :item-key="itemKey"
        :current-value="item.discount"
        :is-enabled="item.isEnabled"
        :field="fields.discount"
        @updateValue="updateValue"
      />
    </div>
  </div>
</template>

<script>
import { itemsKeys } from '@/utils/calculationPriceStep'
import ItemToggle from '@/components/smart/DeepSettings/CalculationPriceTable/ItemToggle'
import ItemField from '@/components/smart/DeepSettings/CalculationPriceTable/ItemField'
import FieldPlug from '@/components/smart/DeepSettings/CalculationPriceTable/FieldPlug'
export default {
  name: 'BodyItem',
  components: { FieldPlug, ItemField, ItemToggle },
  props: {
    item: {
      type: Object,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateValue(obj) {
      if (!this.isLoading) {
        this.$emit('updateValue', obj)
      }
    }
  },
  computed: {
    fields: () => itemsKeys
  }
}
</script>

<style scoped lang="sass">
.table-row
  display: grid
  grid-template-columns: 1fr repeat(3, rem(150)) rem(70)
  gap: rem(15)
  &__cell
    display: flex
    align-items: center
    width: 100%
    &--double
      grid-column: 2 / 4
    .title
      margin-left: rem(16)
      font-weight: bold
      color: $black
      font-size: rem(14)
      line-height: rem(16)
      display: grid
      grid-template-columns: auto 1fr
      align-items: center
      gap: rem(16)
      width: 100%
      padding-right: rem(35)
      &:after
        content: ""
        position: relative
        height: rem(1)
        width: 100%
        background: $ec
  &--disabled
    .table-row
      &__cell
        .title
          color: $black_C
</style>
